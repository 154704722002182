import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';
import { emailRegex } from 'apps/commerce/common/checkout/constants';
import InputLabel from './InputLabel';

function EmailField({ field, disabled }) {
  const { handle, title, required } = field;
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState();
  const [hasError, setHasError] = useState(false);

  const handleChange = (event) => {
    setFieldValue(event.target.value);
    setValue(`customFieldValues.${handle}`, event.target.value);
  };

  useEffect(() => {
    if (fieldValue === undefined) return;
    const isFieldEmpty = fieldValue === '';
    const isValid = emailRegex.test(fieldValue);
    // Required fields must be filled out and valid
    // Non-required fields must be valid if filled out
    if ((required && isFieldEmpty) || (!isFieldEmpty && !isValid)) {
      setHasError(true);
      return;
    }
    setHasError(false);
  }, [fieldValue, handle, required, setValue]);

  return (
    <div>
      {<InputLabel title={title} required={required} handle={handle} />}
      <Input
        inputType="email"
        placeholder={title}
        id={handle}
        data-testid={`${handle}_field`}
        value={fieldValue}
        onChange={handleChange}
        disabled={disabled}
        hasError={hasError}
        message={hasError ? t('form.invalid_email', { field: field.title }) : null}
      />
    </div>
  );
}

EmailField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool.isRequired,
  }).isRequired,
};

EmailField.defaultProps = {
  disabled: false,
};

export default EmailField;
