import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { camelCase } from 'lodash';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getRequest, minorUnitAmount } from 'apps/commerce/popup_checkout/util';
import { verifyDomainForApplePay } from '../../utils';
import CheckoutSettings from '../types/CheckoutSettings';
import Coupon from '../types/Coupon';
import Member from '../types/Member';
import Price, { TaxData } from '../types/Price';
import Site from '../types/Site';

// use this to customize the setTimeout delay for specs
export const useCheckoutConfiguration = {
  getTaxDelay: 3000,
};

// This handles setting all the form values that are used by the different checkout components and
// inputs.
const useCheckout = (): void => {
  const taxTimeout = useRef({} as any);
  const { setValue, watch } = useFormContext();

  // The specific checkout hook should set these after fetching the data from the server for the
  // checkout. Then this can use them to setup the checkout configuration as expected.
  const checkoutLoaded: boolean | undefined = watch('checkoutLoaded');
  const price: Price | undefined = watch('price');
  // For member, we support having either a Member object or undefined which signifies that we don't
  // currently have a member logged-in. The checkout page needs to be notified once the member has
  // been set or not set so we can finish initializing the checkout. The `memberLoaded` value should
  // be set after setting member.
  const memberLoaded: boolean | undefined = watch('memberLoaded');
  const member: Member | undefined = watch('member');
  const site: Site | undefined = watch('site');
  const checkoutSettings: CheckoutSettings | undefined = watch('checkoutSettings');
  const applePayDomainVerified: boolean | undefined = watch('applePayDomainVerified');
  const orderBump = watch('orderBump');

  // set static settings and default dynamic values -- static settings don't change unless you are
  // reloading an entirely new checkout page. These should come from the member, site, or checkout
  // settings data that's loaded from the server (and represents the configuration for this
  // checkout).
  useEffect(() => {
    // We only want to run this once all of the required data has been fetched. The price, site,
    // checkoutSettings, and member shouldn't change once the checkout has been loaded.
    if (!checkoutLoaded && price && site && checkoutSettings && (member || memberLoaded)) {
      if (checkoutSettings.stripePublishableKey && checkoutSettings.stripeAccountId) {
        loadStripe(checkoutSettings.stripePublishableKey, {
          betas: ['elements_enable_deferred_intent_beta_1'],
          stripeAccount: checkoutSettings.stripeAccountId,
        }).then((stripe: Stripe | null) => setValue('stripe', stripe));
      }

      setValue('currency', price.currency);
      setValue('addressRequired', checkoutSettings.addressRequired);
      setValue('nameRequired', checkoutSettings.nameRequired);
      setValue('phoneRequired', checkoutSettings.phoneNumberRequired);
      setValue('paymentMethods', checkoutSettings.paymentMethods);
      setValue('additionalPaymentMethods', checkoutSettings.additionalPaymentMethods);
      setValue(
        'additionalPaymentMethodsPriceLimits',
        checkoutSettings.additionalPaymentMethodsPriceLimits,
      );
      setValue('vendorAccountId', checkoutSettings.vendorAccountId);
      setValue('checkoutPageColor', checkoutSettings.checkoutPageColor);
      setValue('checkoutTextOverride', checkoutSettings.checkoutTextOverride);
      setValue('customFields', checkoutSettings.customFields);
      setValue('applePayDomainVerified', false);
      setValue('taxable', checkoutSettings.taxable);
      setValue('taxInclusive', checkoutSettings.taxInclusive);
      setValue('collectTaxId', checkoutSettings.collectTaxId);
      setValue('countriesToValidateTaxId', checkoutSettings.countriesToValidateTaxId);

      // default dynamic values
      // 'address' stores all parts of an address -- see AddressInput
      setValue('address', { subdivisionRequired: false });
      setValue('amountPriceInCents', 0);
      setValue('attemptReloadWithCardsOnly', false);
      setValue('coupon', undefined);
      setValue('customFieldValues', undefined);
      setValue('dueNowPriceInCents', price?.amount || 0);
      setValue('email', undefined);
      setValue('isFree', false);
      setValue('isPaymentDetailComplete', false);
      setValue('isPaymentInProgress', false);
      setValue('name', undefined);
      setValue('paymentProvider', 'card');
      setValue('phone', undefined);
      setValue('saveCard', site?.cardStorageOptinPreference === 'opt_out');
      setValue('saveCardDefaultValue', false);
      setValue('showSaveCardCheckbox', false);
      setValue('useSavedCard', !!member?.savedCardDetails);
      setValue('taxLoading', false);
      setValue('taxIdValidationStatus', undefined);
      setValue('orderBumpActive', false);

      // This needs to be last as we want to wait too trigger our useEffects and dependent values
      // until we have finished loading all of the checkout data and defaulting the values. This
      // also ensures we only run this useEffect once all of the data is loaded from the server.
      setValue('checkoutLoaded', true);
    }
  }, [checkoutLoaded, price, site, checkoutSettings, member, memberLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  // Setup dynamic values that change as the customer interacts with the page. These should all be
  // guarded by checking if `checkoutLoaded` is true or not which ensures the page has finished
  // loading and these values can be calculated correctly.
  const addressCountry: string | undefined = watch('address.country');
  const addressZip: string | undefined = watch('address.zip');
  const addressLine1: string | undefined = watch('address.addressLine1');
  const addressCity: string | undefined = watch('address.city');
  const addressSubdivision: string | undefined = watch('address.subdivision');
  const coupon: Coupon | undefined = watch('coupon');
  const currentTaxId: string | undefined = watch('taxId');
  const amountPriceInCents: number | undefined = watch('amountPriceInCents');
  const dueNowPriceInCents: number | undefined = watch('dueNowPriceInCents');
  const paymentProvider: string | undefined = watch('paymentProvider');
  const priceOverrideRequired: boolean | undefined = watch('priceOverrideRequired');
  const priceOverride: number | undefined = watch('priceOverride');
  const priceOverrideInCents: number | undefined = watch('priceOverrideInCents');
  const quantity: integer | undefined = watch('quantity');
  const useSavedCard: boolean | undefined = watch('useSavedCard');
  const showSaveCardCheckbox: boolean | undefined = watch('showSaveCardCheckbox');
  const taxable: boolean | undefined = watch('taxable');
  const countriesToValidateTaxId: string[] | undefined = watch('countriesToValidateTaxId');
  const taxInclusive: boolean | undefined = watch('taxInclusive');
  const taxData: TaxData | undefined = watch('price.tax');
  const orderBumpActive: boolean | undefined = watch('orderBumpActive');
  const additionalPaymentMethods: string[] | undefined = watch('additionalPaymentMethods');
  const additionalPaymentMethodsPriceLimits: object | undefined = watch(
    'additionalPaymentMethodsPriceLimits',
  );

  // TODO: Ideally we remove offer token and invoice token from here as these are too specific for
  // this generic checkout code, the tax endpoint should be more generic and not require these
  const offerToken: string | undefined = watch('offer.token');
  const invoiceToken: string | undefined = watch('invoice.invoiceToken');

  useEffect(() => {
    if (!checkoutLoaded || !price) return;

    const orderBumpPrice = orderBumpActive ? orderBump?.price?.amount : 0;
    const taxPrice = taxData && !taxInclusive ? taxData.overallAmount : 0;
    const orderBumpTaxPrice = orderBumpActive && taxData ? taxData.orderBumpOverallAmount : 0;
    const additionalAmount = orderBumpPrice + taxPrice + orderBumpTaxPrice;

    if (coupon) {
      // The coupon API returns the due now amount so we can just use
      // that when a coupon is present.
      setValue('dueNowPriceInCents', coupon.dueNowAmount + additionalAmount);
    } else if (priceOverrideRequired) {
      // For PWYW offers, return the price override
      setValue('dueNowPriceInCents', priceOverrideInCents + additionalAmount);
    } else if (!price.subscriptionPlanAttributes && quantity > 1) {
      setValue('dueNowPriceInCents', price.amount * quantity + additionalAmount);
    } else {
      setValue('dueNowPriceInCents', price.amount + additionalAmount);
    }
  }, [
    checkoutLoaded,
    price,
    orderBumpActive,
    orderBump,
    taxData,
    taxInclusive,
    coupon,
    priceOverrideRequired,
    priceOverrideInCents,
    quantity,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkoutLoaded || dueNowPriceInCents === undefined) return;

    const orderBumpPrice = orderBumpActive ? orderBump?.price?.amount : 0;
    const orderBumpTaxPrice = orderBumpActive && taxData ? taxData.orderBumpOverallAmount : 0;

    setValue('amountPriceInCents', dueNowPriceInCents - orderBumpPrice - orderBumpTaxPrice);
  }, [checkoutLoaded, dueNowPriceInCents, orderBumpActive, orderBump, taxData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkoutLoaded) return;

    // A gift coupon makes the offer free
    if (coupon) {
      setValue('isFree', coupon.giftCoupon);
    } else if (price && price.subscriptionPlanAttributes) {
      // For a subscription offer, $0 payments + $0 setup fee means free.
      const { paymentAmount, setupFee } = price.subscriptionPlanAttributes;
      setValue('isFree', paymentAmount + setupFee === 0);
    } else {
      // For a non-subscription offer, $0 due now means free.
      setValue('isFree', dueNowPriceInCents === 0);
    }
  }, [checkoutLoaded, price, dueNowPriceInCents, coupon]);

  // Fetch tax data as the address changes
  useEffect(() => {
    if (!checkoutLoaded || (!offerToken && !invoiceToken) || !taxable) return;

    if (addressCountry || addressZip || addressLine1 || addressCity || addressSubdivision) {
      setValue('taxLoading', true);
      clearTimeout(taxTimeout?.current as any | undefined);
      taxTimeout.current = setTimeout(() => {
        const taxPayload = {
          country: addressCountry,
          zip: addressZip,
          line1: addressLine1,
          city: addressCity,
          subdivision: addressSubdivision,
          couponCode: coupon?.couponCode,
          taxId: currentTaxId,
          quantity,
          priceOverride,
          offerToken,
          invoiceToken,
        };
        getRequest('/api/checkout/tax', { params: taxPayload })
          .then((response) => {
            setValue('price.tax', response.data.data.tax as TaxData);
            setValue('taxLoading', false);
          })
          .catch((err) => {
            console.warn('Failed loading tax information');
            setValue('taxLoading', false);
          });
      }, useCheckoutConfiguration.getTaxDelay);
      return () => clearTimeout(taxTimeout.current); // eslint-disable-line consistent-return
    }
  }, [
    checkoutLoaded,
    taxable,
    addressCountry,
    addressZip,
    addressLine1,
    addressCity,
    addressSubdivision,
    currentTaxId,
    coupon,
    quantity,
    priceOverride,
    offerToken,
    invoiceToken,
  ]);

  useEffect(() => {
    if (!checkoutLoaded || !site || !taxable || !countriesToValidateTaxId) return;

    if (countriesToValidateTaxId.includes(addressCountry) && currentTaxId) {
      const timer = setTimeout(() => {
        const payload = {
          siteId: site.id,
          country: addressCountry,
          taxId: currentTaxId,
        };
        getRequest(`/api/checkout/tax_id_status`, { params: payload })
          .then((response) => {
            setValue('taxIdValidationStatus', response.data.data.status);
          })
          .catch((err) => console.warn('Failed loading tax ID status'));
      }, 1000);
      return () => clearTimeout(timer); // eslint-disable-line consistent-return
    }
  }, [checkoutLoaded, taxable, countriesToValidateTaxId, addressCountry, currentTaxId]);

  useEffect(() => {
    if (
      !checkoutLoaded ||
      !amountPriceInCents ||
      !additionalPaymentMethods ||
      !additionalPaymentMethodsPriceLimits
    )
      return;

    let inEligiblePaymentMethods: string[] = [];
    inEligiblePaymentMethods = additionalPaymentMethods?.filter((method: string) => {
      const methodPriceLimit = additionalPaymentMethodsPriceLimits[camelCase(method)];
      if (!methodPriceLimit) return false;
      return (
        amountPriceInCents < methodPriceLimit.minPrice ||
        amountPriceInCents > methodPriceLimit.maxPrice
      );
    });

    if (inEligiblePaymentMethods === undefined) return;
    const isValidAmount = inEligiblePaymentMethods.length === 0;

    if (!isValidAmount) {
      const newMethods = additionalPaymentMethods?.filter(
        (method: string) => !inEligiblePaymentMethods.includes(method),
      );
      setValue('additionalPaymentMethods', newMethods);
    } else {
      setValue('additionalPaymentMethods', additionalPaymentMethods);
    }
  }, [checkoutLoaded, amountPriceInCents, additionalPaymentMethodsPriceLimits]);

  useEffect(() => {
    if (!checkoutLoaded || !priceOverride) return;

    setValue('priceOverrideInCents', minorUnitAmount(priceOverride, price.exponent));
  }, [checkoutLoaded, priceOverride, price]);

  useEffect(() => {
    if (!checkoutLoaded) return;

    const value =
      !useSavedCard &&
      price?.priceType !== 'recurring' &&
      site?.cardStorageOptinPreference &&
      ['opt_in', 'opt_out'].includes(site.cardStorageOptinPreference) &&
      paymentProvider !== 'external_paypal';
    setValue('showSaveCardCheckbox', value);
  }, [checkoutLoaded, useSavedCard, price, site, paymentProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkoutLoaded) return;

    const value = !showSaveCardCheckbox && site?.cardStorageOptinPreference === 'opt_in';
    setValue('saveCardDefaultValue', value);
  }, [checkoutLoaded, showSaveCardCheckbox, site]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkoutLoaded) return;

    // skip if no ApplePay session exists
    if (!window.ApplePaySession) return;
    // skip if ApplePay is not enabled on the Offer
    if (!site || !checkoutSettings?.additionalPaymentMethods?.includes('apple_pay')) return;
    // skip if the domain has already been verified
    if (applePayDomainVerified) return;

    verifyDomainForApplePay(site).then(async () => {
      setValue('applePayDomainVerified', true);
    });
  }, [checkoutLoaded, site, checkoutSettings]);
};

export default useCheckout;
