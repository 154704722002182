import React from 'react';
import { useTranslation } from 'react-i18next';
import { Frame, Icon } from '@kajabi/sage-react';

const SecureCheckout = () => {
  const { t } = useTranslation();

  return (
    <p data-testid="secure-checkout" className="secure-checkout">
      <Icon icon={Icon.ICONS.LOCK} size={Icon.SIZES.SM} /> {t('form.transaction_security')}
    </p>
  );
};

export default SecureCheckout;
