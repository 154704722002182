import React from 'react';
import PropTypes from 'prop-types';
import { SageClassnames, Frame } from '@kajabi/sage-react';
import TextField from './TextField';
import TextAreaField from './TextAreaField';
import CheckboxField from './CheckboxField';
import CountryField from './CountryField';
import RadioButtonsField from './RadioButtonsField';
import SelectBoxField from './SelectBoxField';
import EmailField from './EmailField';

// Dictionary of custom field types and their corresponding components.
const CUSTOM_FIELD_TYPES = {
  TextField,
  PhoneField: TextField,
  RadioButtonsField,
  SelectBoxField,
  CheckboxField,
  EmailField,
  TextAreaField,
  CountryField,
};

// Container component for custom fields.
const CustomFields = ({ customFields, disabled }) => {
  if (!customFields || customFields.length === 0) return null;
  return (
    <Frame
      direction={Frame.DIRECTIONS.VERTICAL}
      gap={Frame.GAPS.MD}
      className={`custom-fields ${SageClassnames.SPACERS.MD_BOTTOM}`}
      width={Frame.WIDTHS.FILL}
      align={Frame.ALIGNMENTS.START_SPREAD}
    >
      {customFields.map((field) => {
        // Renders a field component based on the field type.
        const { type } = field;
        const Element = CUSTOM_FIELD_TYPES[type];
        if (!Element) return null;
        return <Element key={field.handle} field={field} disabled={disabled} />;
      })}
    </Frame>
  );
};

CustomFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      selectOptions: PropTypes.arrayOf(PropTypes.string),
      required: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default CustomFields;
