import React from 'react';
import PropTypes from 'prop-types';
import { Frame, SageClassnames } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';

const InputLabel = ({ title, required, handle }) => {
  const { t } = useTranslation();
  return (
    <Frame
      className={`custom-fields__label ${SageClassnames.SPACERS.XS_BOTTOM}`}
      direction={Frame.DIRECTIONS.HORIZONTAL}
      align={Frame.ALIGNMENTS.CENTER_SPREAD}
      gap={Frame.GAPS.XS}
    >
      <label
        style={{ margin: 0 }}
        htmlFor={handle}
        className="sage-input__label custom-fields__label-text"
      >
        {title}
      </label>
      {!required && (
        <small className={`custom-fields__label-tag ${SageClassnames.TYPE_COLORS.GREY_600}`}>
          {t('form.optional')}
        </small>
      )}
    </Frame>
  );
};

export default InputLabel;

InputLabel.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handle: PropTypes.string.isRequired,
};
