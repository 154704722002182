import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SageClassnames, SageTokens, Frame, Icon, Loader } from '@kajabi/sage-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { localizedPriceFromMinorUnit } from 'apps/commerce/popup_checkout/util';

export const SalesTax = ({ taxLoading }) => {
  const [showBreakdown, setShowBreakdown] = useState(false);
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const { price, taxInclusive } = getValues();
  const { tax, currency, exponent } = price;

  const onBreakdownClick = () => {
    setShowBreakdown(!showBreakdown);
  };
  const salesTaxLabel = taxInclusive ? t('form.sales_tax_inclusive') : t('form.sales_tax');
  const percentDisplay = tax ? `(${tax.overallPercent})` : '';
  const amountDisplay = tax
    ? localizedPriceFromMinorUnit(currency, tax.overallAmount, exponent)
    : '-';
  let labelClassNames, breakdownIcon;
  if (showBreakdown) {
    labelClassNames = SageClassnames.TYPE_COLORS.GREY_900;
    breakdownIcon = 'caret-down';
  } else {
    labelClassNames = SageClassnames.TYPE_COLORS.GREY_600;
    breakdownIcon = 'caret-up';
  }
  const hasMultipleRates = tax && tax.rates.length > 1;
  let rates = [];
  if (hasMultipleRates && tax) {
    rates = tax.rates.map((rate) => (
      <Frame
        key={rate.name}
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.SM}
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        className={`price-detail sales-tax-rate ${SageClassnames.TYPE_COLORS.GREY_600}`}
      >
        <span className="summary-item__title-text">
          {rate.name} ({rate.percent})
        </span>
        <span className="summary-item__price">
          {localizedPriceFromMinorUnit(currency, rate.amount, exponent)}
        </span>
      </Frame>
    ));
  }

  return (
    <>
      <Frame
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.SM}
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        className={`price-detail sales-tax-overall-rate ${SageClassnames.TYPE.BODY_SEMI} ${labelClassNames}`}
        data-testid="sales-tax-overall-rate"
      >
        <span className="summary-item__title-text">
          <span>
            {salesTaxLabel} {percentDisplay}
          </span>
          {hasMultipleRates && (
            <Icon
              className={SageClassnames.SPACERS.XS_LEFT}
              data-testid="sales-tax-breakdown-toggle"
              onClick={onBreakdownClick}
              icon={breakdownIcon}
              size="sm"
            />
          )}
        </span>
        <p className="summary-item__price" data-testid="sales-tax-overall-amount">
          {taxLoading ? <Loader loading type={Loader.TYPES.SPINNER} /> : amountDisplay}
        </p>
      </Frame>
      {hasMultipleRates && showBreakdown && (
        <Frame
          gap={Frame.GAPS.SM}
          border={Frame.BORDERS.DEFAULT}
          borderRadius={Frame.BORDER_RADII.MD}
          padding={Frame.PADDINGS.SM}
          background={SageTokens.COLOR_PALETTE.WHITE}
          className={`sales-tax-rate-breakdown ${SageClassnames.TYPE.BODY_SEMI} ${SageClassnames.SPACERS.XS_TOP} ${SageClassnames.TYPE_COLORS.GREY_200}`}
          data-testid="sales-tax-rate-breakdown"
        >
          {rates}
        </Frame>
      )}
    </>
  );
};

SalesTax.propTypes = {
  taxLoading: PropTypes.bool.isRequired,
};

export default SalesTax;
