import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Radio, Frame } from '@kajabi/sage-react';
import InputLabel from './InputLabel';

function RadioButtonsField({ field, disabled }) {
  const { handle, title, required, selectOptions } = field;
  const [fieldValue, setFieldValue] = React.useState();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const hasError = required && fieldValue === '';
  const errorMsg = hasError ? t('form.required_field', { field: field.title }) : null;

  const handleChange = (newValue) => {
    setFieldValue(newValue);
    setValue(`customFieldValues.${handle}`, newValue);
  };

  return (
    <>
      <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.NONE}>
        {<InputLabel title={title} required={required} handle={handle} />}
        <ul className="sage-list">
          {selectOptions.map((option) => (
            <Radio
              key={option}
              id={option}
              itemInList
              label={option}
              onChange={handleChange}
              value={option}
              checked={fieldValue === option}
              disabled={disabled}
            />
          ))}
        </ul>
      </Frame>
      {hasError && (
        <div className="sage-form-field--error">
          <div className="sage-input__info">
            <div className="sage-input__message">{errorMsg}</div>
          </div>
        </div>
      )}
    </>
  );
}

RadioButtonsField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool.isRequired,
  }).isRequired,
};

RadioButtonsField.defaultProps = {
  disabled: false,
};

export default RadioButtonsField;
