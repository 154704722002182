import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@kajabi/sage-react';
import InputLabel from './InputLabel';

function SelectBoxField({ field, disabled }) {
  const { handle, title, required, selectOptions } = field;
  const [fieldValue, setFieldValue] = React.useState();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const hasError = required && fieldValue === '';

  const handleChange = (event) => {
    setFieldValue(event.target.value);
    setValue(`customFieldValues.${handle}`, event.target.value);
  };

  return (
    <div className="sage-form-group">
      {<InputLabel title={title} required={required} handle={handle} />}
      <Select
        key={handle}
        id={handle}
        onChange={handleChange}
        value={fieldValue}
        options={['', ...selectOptions]}
        disabled={disabled}
        hasError={hasError}
        message={hasError ? t('form.required_field', { field: field.title }) : null}
      />
    </div>
  );
}

SelectBoxField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool.isRequired,
  }).isRequired,
};

SelectBoxField.defaultProps = {
  disabled: false,
};

export default SelectBoxField;
