import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, SageClassnames } from '@kajabi/sage-react';
import capitalize from 'lodash/capitalize';

function CheckboxField({ field, disabled }) {
  const { handle, title, required } = field;
  const [checked, setChecked] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const hasError = required && touched && !checked;

  // Default value to '0' when not required so that the required field validation
  // doesn't prevent form submission. Also, when a custom checkbox is not checked,
  // the API expects a '0' to be sent.
  React.useEffect(() => {
    setValue(`customFieldValues.${handle}`, required ? '' : '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Returning an empty string will result in the field being in an error state.
  const checkedToString = (checkedValue) => {
    if (checkedValue) return '1';
    return required ? '' : '0';
  };

  const handleChange = () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);
    setTouched(true);
    setValue(`customFieldValues.${handle}`, checkedToString(newCheckedState));
  };

  return (
    <Checkbox
      label={title}
      id={handle}
      data-testid={`${handle}_field`}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      hasError={hasError}
      message={hasError && capitalize(t('form.required'))}
      customContent={
        !required && (
          <small className={SageClassnames.TYPE_COLORS.GREY_600}>{t('form.optional')}</small>
        )
      }
    />
  );
}

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool.isRequired,
  }).isRequired,
};

CheckboxField.defaultProps = {
  disabled: false,
};

export default CheckboxField;
