import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@kajabi/sage-react';
import InputLabel from './InputLabel';

function TextAreaField({ field, disabled }) {
  const { handle, title, required } = field;
  const [fieldValue, setFieldValue] = useState();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const hasError = required && fieldValue === '';

  const handleChange = (event) => {
    setFieldValue(event.target.value);
    setValue(`customFieldValues.${handle}`, event.target.value);
  };

  return (
    <div>
      {<InputLabel title={title} required={required} handle={handle} />}
      <Textarea
        placeholder={title}
        id={handle}
        data-testid={`${handle}_field`}
        value={fieldValue}
        onChange={handleChange}
        disabled={disabled}
        hasError={hasError}
        message={hasError ? t('form.required_field', { field: field.title }) : null}
        style={{ resize: 'vertical' }}
      />
    </div>
  );
}

TextAreaField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool.isRequired,
  }).isRequired,
};

TextAreaField.defaultProps = {
  disabled: false,
};

export default TextAreaField;
